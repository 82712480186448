// |--------------------------------------------------------------------------
// | Sliders
// |--------------------------------------------------------------------------
// |
// | Création de sliders.
// |--------------------------------------------------------------------------
import { OBSERVER } from './../main.js'

// Slider de la bannière | Accueil
export const slickHomeBanner = () => {

  // Slider des images
  $('.js-home-banner-image-slider').slick({
    arrows: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    rtl: true,
    asNavFor: '.js-home-banner-text-slider, .js-home-banner-tiny-slider',
    responsive: [
      {
        breakpoint: 800,
        settings: {
          fade: true,
          adaptiveHeight: true
        }
      }
    ]
  })

  // Slider du texte
  $('.js-home-banner-text-slider').slick({
    arrows: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    fade: true,
    asNavFor: '.js-home-banner-image-slider, .js-home-banner-tiny-slider',
    responsive: [
      {
        breakpoint: 800,
        settings: {
          adaptiveHeight: true
        }
      }
    ]
  })

  // Petit slider
  $('.js-home-banner-tiny-slider').slick({
    arrows: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    rtl: true,
    asNavFor: '.js-home-banner-image-slider, .js-home-banner-text-slider',
  })

  // Flèches
  $(document).find('.js-home-banner-tiny-slider-prev').on('click', () => {
    $(document).find('.js-home-banner-tiny-slider').slick('slickPrev')
  })

  $(document).find('.js-home-banner-tiny-slider-next').on('click', () => {
      $(document).find('.js-home-banner-tiny-slider').slick('slickNext')
  })

  // Nombres
  slickSlideCount('.js-home-banner-tiny-slider', '.home-banner__tiny-slider-nav .slideNumber .current', '.home-banner__tiny-slider-nav .slideNumber .total')
}

// Slider des événements ( Accueil)
export const slickFeaturedEvents = () => {
  $('.js-featured-events-slider').slick({
    arrows: false,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
    cssEase: 'cubic-bezier(0.65, 0, 0.35, 1)',
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll:1,
  })
  
  // Flèches
  $('.js-featured-events-slider-prev').on('click', () => {
    $('.js-featured-events-slider').slick('slickPrev')
  })
  
  $('.js-featured-events-slider-next').on('click', () => {
      $('.js-featured-events-slider').slick('slickNext')
  })

  let slider = $('.js-featured-events-slider')
  let bigImageContainer = $('.js-big-image-container')
  let tinyImageContainer = $('.js-tiny-image-container')
  let bigImage = $('.js-big-image')
  let tinyImage = $('.js-tiny-image')
  
  slider.on("beforeChange", function () {
    bigImageContainer.addClass('cover')
    tinyImageContainer.addClass('cover')
  })

  slider.on("afterChange", function (event, slick, currentSlide) {
    let nextSlide = currentSlide + 1
    bigImageContainer.removeClass('cover')
    tinyImageContainer.removeClass('cover')
    changeUrl(currentSlide, nextSlide)
  })

  function changeUrl(currentSlide, nextSlide) {
    let urlBigImage = $(`.js-featured-events-slider [data-slick-index="${currentSlide}"] .featured-events__slider-slide`).attr('data-img')
    let urlTinyImage = $(`.js-featured-events-slider [data-slick-index="${nextSlide}"] .featured-events__slider-slide`).attr('data-img')

    bigImage.attr('src', urlBigImage)
    tinyImage.attr('src', urlTinyImage)
  }
}

// Slider des alertes
export const slickAlert = () => {
  $('.js-alert-slider').slick({
    arrows: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
  })

  $('.js-alert-mobile-slider').slick({
    arrows: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    infinite: true,
  })
  
  // Flèches
  $('.js-alert-slider-prev').on('click', () => {
    $('.js-alert-slider').slick('slickPrev')
  })
  
  $('.js-alert-slider-next').on('click', () => {
      $('.js-alert-slider').slick('slickNext')
  })

  $('.js-alert-mobile-slider-prev').on('click', () => {
    $('.js-alert-mobile-slider').slick('slickPrev')
  })
  
  $('.js-alert-mobile-slider-next').on('click', () => {
      $('.js-alert-mobile-slider').slick('slickNext')
  })

  // Nombres
  slickSlideCount('.js-alert-slider', '.alert__slider-nav .slideNumber .current', '.alert__slider-nav .slideNumber .total')
  slickSlideCount('.js-alert-mobile-slider', '.alert-mobile__slider-nav .slideNumber .current', '.alert-mobile__slider-nav .slideNumber .total')
}

// Slider des filtres
export const slickFilter = () => {
  const container = document.querySelector('.filter__slider-container');

  let slides;
  let arrows;
  let i;
  let totalWidth = 0;

  if (container) {
    slides = container.querySelectorAll('.filter__slider-slide');
    arrows = document.querySelector('.filter__arrows');

    for (i = 0; i < slides.length; i += 1) {
      const slide = slides[i];
      const slideWidth = slide.offsetWidth;

      totalWidth += slideWidth + 45;
    }

    onResize();
    OBSERVER.add({ name: 'filterSlider', event: 'resize', function: onResize })
    OBSERVER.on('filterSlider');
  }

  function onResize() {
    checkOverflow()
  }

  // Afficher les flèches seulement si les liens sortent de leur conteneur
  function checkOverflow() {
    const initialized = container.querySelector('.slick-initialized');

    if(container.offsetWidth < totalWidth) {

      if (initialized === null) {
        const filterSlider = $('.js-filter-slider');
        const prevArrow = $('.js-filter-slider-prev');
        const nextArrow = $('.js-filter-slider-next');

        let firstSlide;
        let activeFirstSlide;

        filterSlider.slick({
          arrows: false,
          speed: 600,
          autoplay: false,
          infinite: false,
          slidesToScroll: 1,
          slidesToShow: 1,
          variableWidth: true,
          swipe: false,
        })

        setTimeout(() => {
          firstSlide = $('.js-filter-slider .slick-slide').first();
          activeFirstSlide = firstSlide.hasClass('slick-active');

          if (activeFirstSlide) {
            prevArrow[0].classList.add('--disable');
          } else {
            prevArrow[0].classList.remove('--disable');
          }
        }, 100);

        filterSlider.on('afterChange', function(){

          const lastSlide = $('.filter__slider-slide').last();
          const lastSlideRight = lastSlide[0].getBoundingClientRect().right;
          const containerRight = container.getBoundingClientRect().right;
          activeFirstSlide = firstSlide.hasClass('slick-active');

          if (activeFirstSlide) {
            prevArrow[0].classList.add('--disable');
          } else {
            prevArrow[0].classList.remove('--disable');
          }

          if (lastSlideRight <= containerRight) {
            nextArrow[0].classList.add('--disable');
          } else {
            nextArrow[0].classList.remove('--disable');
          }
        });

        prevArrow.on('click', () => {
          $('.js-filter-slider').slick('slickPrev')
        })
        
        nextArrow.on('click', () => {
          $('.js-filter-slider').slick('slickNext')
        })
      }

      arrows.classList.add('--visible')
    } else {
      if (initialized) {
        $('.js-filter-slider').slick('unslick')
      }
      arrows.classList.remove('--visible')
    }
  }
}

// Fonction permettant d'afficher la slide active et le nombre total de slide
function slickSlideCount(slickElement, currentElement, totalElement)  {
  $(slickElement).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let slidePosition = nextSlide + 1
    $(currentElement).html(slidePosition)
  })

  let total = $(slickElement + ' .slick-slide:not(.slick-cloned)').length
  let displayedTotal = total
  $(totalElement).html(displayedTotal)
}
