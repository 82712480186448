// |--------------------------------------------------------------------------
// | Overlay share
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner l'overlay
// | de partage.
// |
// | IMPORTS _____
// | -> overlay.js
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import Overlay from '../../libraries/overlay.js'
import { copyTextToClipboard } from './functions.js'
// ---------------------------------------------------------------------------


export const overlayShare = () => {
  let siteName = window.config.site_name

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.open-overlay-share',
        close: '.close-overlay-share',
      }
    },
    options: {
      speed: 600,
    }
  }).init()
  
  $('.js-copy-share').click( function() {
    var link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
    return false
  })

  $('.js-share').on('click', function(e) {
    if(e.currentTarget.dataset.rubric){
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject='+ siteName +'&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject='+ siteName +'&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}