// |--------------------------------------------------------------------------
// | Fonctions
// |--------------------------------------------------------------------------
// |
// | Les divers scripts du site.
// |--------------------------------------------------------------------------

import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset } from './helper.js'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })
  
  return animation.finished
}

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins(){
  $('.dynamic p > a.button, .dynamic p > a.button--secondary').each(function() {
    $(this).parent().addClass('buttonMargin')
  })
  $('.dynamic p.buttonMargin').each(function() {
    if(!($(this).prev().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--first')
    }
    if(!($(this).next().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--last')
    }
  })
}

// Ajouter un <span> dans les boutons pour l'animation
export function addSpanToButtons() {
  let buttons = $('.button, .button--secondary, .js-hover-btn')
  buttons.wrapInner('<span></span>')
}

// Ajouter un <span> dans les liens pour le souligné
export function addSpanToLinks() {
  let i;
  let linksInnerHtml;
  let newLinksInnerHtml;
  const links = document.querySelectorAll('.dynamic a:not(.primary-button):not(.secondary-button)');
  const linksLength = links.length;

  for (i = 0; i < linksLength; i += 1) {
    linksInnerHtml = links[i].innerHTML;
    newLinksInnerHtml = `<span>${linksInnerHtml}</span>`;
    links[i].innerHTML = newLinksInnerHtml;
  }
}

// Ajouter les icones svg pour les boutons et les liens textes
export function addSvgToLinks() {
  let themePath = window.config.theme_path

  let linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])')
  linksExternal.forEach(function(link) {
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-external')
    svgElem.appendChild(useElem);
    link.appendChild(svgElem)
  })

  let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]')
  linksDocuments.forEach(function(link) {
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/'+themePath+'/assets/medias/images/icons/symbols.svg#ico-download')
    svgElem.appendChild(useElem);
    link.appendChild(svgElem)
  })

}

export function calendar() {
  if (!document.querySelector('.calendar')){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector('.calendar').dataset.list
  let currentDate = document.querySelector('.calendar').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  var datesEnabled = eventsDatesList.split(',')

  $('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    var theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ barba.go('/evenements/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('.overlay-calendar .overlay-calendar__close').trigger('click')
  })

  $('.calendar').datepicker('update', currentDate)
}


// Fonction gérant les alertes
export function alerts() {
  const onClick = () => document.querySelector('html').classList.remove('show-alerts')

  OBSERVER.add({ name: 'alerts', event: 'click', function: onClick,  target: '.js-alert-close' })
  OBSERVER.add({ name: 'alerts', event: 'click', function: onClick,  target: '.js-alert-mobile-close' })
  OBSERVER.on('alerts')
}


// Copier du texte
export function copyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.replace(/ /g,"-")
  result = result.toLowerCase()

  return result
}