// |--------------------------------------------------------------------------
// | Popup et sidepanel
// |--------------------------------------------------------------------------
// |
// | Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
// |--------------------------------------------------------------------------



// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import { OBSERVER } from '../main.js'
import { Accordions } from './accordions'
import Overlay from '../../libraries/overlay.js'



// ---------------------------------------------------------------------------
// Fonction gérant l'overlay des liens rapides
// ---------------------------------------------------------------------------
export const overlayFastlinks = () => {
  let fastlinks = new Overlay({
    name: 'Fastlinks',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-fastlinks', switch: '.js-close-all-overlay, .js-toggle-overlay-search, .js-toggle-overlay-menu', close: '.js-close-overlay-fastlinks' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      disabledPageScroll: true,
      closeOnResize: false
    }
  })
  
  fastlinks.init()
}


// ---------------------------------------------------------------------------
// Fonction gérant l'overlay de recherche
// ---------------------------------------------------------------------------
export const overlaySearch = () => {
  let search = new Overlay({
    name: 'search',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-search', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-toggle-overlay-fastlinks', close: '.js-close-overlay-search' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: false // Empêche l'overlay de se fermer tout seul en mobile lorsque le clavier du mobile s'ouvre
    }
  })
  
  const onOpenComplete = () => { searchInput.focus() }

  search.init()

  const searchInput = document.querySelector('.js-input-to-clear')
  OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onOpenComplete })
  OBSERVER.on('overlaySearch')

  activateWithKeyUp('#form-search #form-search-input', '#autocomplete', true)

  function activateWithKeyUp(input, selector, htmlClass = false) {

    var ajaxRequest = false

    $(input).keyup(function(e) {

      if( ajaxRequest ) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort()
      }

      var keycode = (e.keyCode ? e.keyCode : e.which)

      if ( keycode != 13 ) {
        if ($(this).val().length >= 4) {

          ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
            data: { q: $(this).val() },
            update: { 'rechercheAutocomplete::autocomplete':'#overlay-search #autocomplete'},
            complete: function (data) {
              // Results loaded.
              ajaxRequest = false
              $(selector).animate({height: 'show'}, {
                duration: 200
              })
            },
          })
        } else {

          $(selector).stop(true, false).animate({height: 'hide'}, {
            duration: 200
          })
        }
      }
    })

    $('.js-clear-input').click(function() {
      $(selector).stop(true, false).animate({height: 'hide'}, {
        duration: 200
      })
    })
  }
}


// ---------------------------------------------------------------------------
// Fonction gérant l'overlay menu
// ---------------------------------------------------------------------------
export const overlayMenu = () => {
  let menu = new Overlay({
    name: 'menu',
    events: {
      close: true
    },
    create: { close: false },
    click: { buttons: { toggle: '.js-toggle-overlay-menu', switch: '.js-close-all-overlay, .js-toggle-overlay-search, .js-toggle-overlay-fastlinks', close: '.js-close-overlay-menu' }},
    options: {
      speed: 800,
      goToSelector: 'html, body', 
      closeOnResize: false
    }
  })
  
  menu.init()

  const mainLevel = new Accordions({
    name: 'MainLevel',
    containerClass: 'js-accordions-main-level',
    accordionClass: 'js-accordion-main-level',
    toggleButtonClass: 'js-accordion-toggle-main-level',
    contentClass: 'js-accordion-content-main-level',
    openedClass: 'js-accordion-opened-main-level',
  })

  const secondLevel = new Accordions({
    name: 'SecondLevel',
    containerClass: 'js-accordions-second-level',
    accordionClass: 'js-accordion-second-level',
    toggleButtonClass: 'js-accordion-toggle-second-level',
    contentClass: 'js-accordion-content-second-level',
    openedClass: 'js-accordion-opened-second-level',
  })

  const onClose = () => {
    mainLevel.closeAll()
    secondLevel.closeAll()
  }

  const onCloseMainLevel = () => {
    secondLevel.closeAll()
  }

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseOverlayMenu',
    function: onClose
  })

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseAccordionsMainLevel',
    function: onCloseMainLevel
  })

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseAllAccordionsMainLevel',
    function: onCloseMainLevel
  })

  OBSERVER.on('overlayMenu')
}


// ---------------------------------------------------------------------------
// Fonction gérant l'overlay de calendrier
// ---------------------------------------------------------------------------
export const overlayCalendar = () => {
  let calendar = new Overlay({
    name: 'calendar',
    create: {
      background: false
    },
    click: {
      buttons: {
        open: '.js-open-overlay-calendar',
        close: '.js-close-overlay-calendar',
      }
    },
    options: {
      speed: 800,
    }
  })

  calendar.init()
}


// ---------------------------------------------------------------------------
// Fonction gérant l'overlay du popup
// ---------------------------------------------------------------------------
export function overlayPopup() {
  let popup = new Overlay({
    name: 'popup',
    create: {
      background: false
    },
    timeout: {
      delay: 300,
    },
    click: {
      buttons: {
        close: '.js-close-overlay-popup'
      }
    },
    options: {
      speed: 800,
    }
  })

  popup.init()
}

