// |--------------------------------------------------------------------------
// | Transition avec barba et gestion des vues
// |--------------------------------------------------------------------------
// |
// | C'est ici que les transitions entre les pages sont gérées, on y retrouve
// | les différentes vues spécifiques à chaque page. Les vues sont également
// | définies ici.
// |--------------------------------------------------------------------------

import barba from '@barba/core'
import Overlay from '../libraries/overlay.js'
import { defaultTransition } from './transitions/default-transition.js'
import { adjustButtonsMargins, addSpanToButtons, addSvgToLinks, calendar, dragAndSlide, clickToScrollToBlock, alerts, slugifyProvider, addSpanToLinks } from './functions/functions.js'
import { fileUpload, select, textareaHeight, formExample, inputsAndTextareaLabel, clearInput, formContact, formNewsletter, formJobs, formSearch } from './functions/form.js'
import { hundredVH, isMobile, removeHoverOnMobile, sizeH1, wrapYtVideo } from './functions/helper.js'
import { Accordions } from './functions/accordions.js'
import { backToTopAndLinks } from './functions/back-to-top-and-links.js'
import { overlayCalendar, overlayFastlinks, overlayMenu, overlayPopup, overlaySearch } from './functions/overlays.js'
import { SCROLLFIRE } from './main.js'
import { overlayShare } from './functions/overlay-share.js'
import { slickAlert, slickFeaturedEvents, slickFilter, slickHomeBanner } from './functions/sliders.js'

window.barba = barba
window.slugifyProvider = slugifyProvider

export const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()
  })
  barba.hooks.afterLeave (() => {})
  barba.hooks.enter ((data) => {
    var ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
    var ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
    var ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
    var ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
    document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
    document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
    document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
    document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
  })
  barba.hooks.after(() => {
    gtag('event', 'page_view', { 'page_path': location.pathname + location.search })
  })
  barba.init({

    // Options
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    // Transitions
    transitions: [
      defaultTransition()
    ],

    // Views
    views: [{
      namespace: 'standardView',
      beforeEnter() {

        // Scrollfire
        SCROLLFIRE.init()

        // Les sliders
        slickHomeBanner()
        slickFeaturedEvents()
        slickAlert()
        slickFilter()

        // Mobile
        isMobile() // Vérifie si l'usager est sur mobile ou non
        removeHoverOnMobile() // Enlève les effets d'hover en mobile

        // Permet de donner la hauteur exact en mobile de 100vh
        hundredVH()

        // Réduit la grosseur des titres lorsqu'il ya trop de caractères
        sizeH1()

        // Boutons et liens
        adjustButtonsMargins() // Ajoute du margin aux boutons
        addSpanToButtons() // Ajoute des span dans les boutons
        addSpanToLinks() // Ajoute des span aux liens
        addSvgToLinks() // Ajoute des svg aux liens et boutons

        // Rubriques
        new Accordions() // Rubriques

        // Filtre de catégories
        // dragAndSlide({ name: 'filter' }) // Permet au filtre de catégories de défiler de gauche à droite

        // Bouton de retour vers le haut
        backToTopAndLinks() // Function du bouton de retour vers le haut

        // Formulaires
        formSearch('#form-search', '#form-search-submit')
        formSearch('#form-search-results', '#submit-search-results')
        formNewsletter()
        formContact() // Formulaire pour la page de contact
        formJobs() // Formulaire pour les pages d'emplois et emplois détail
        fileUpload() // Téléchargement de document
        select() // Select
        textareaHeight() // Ajuste la hauteur des textearea
        inputsAndTextareaLabel() // Label des input et textearea
        clearInput() // Efface le contenue des input

        // Les scrollTo
        clickToScrollToBlock({ selector: '[data-js="go-to-form"]', scrollTo: '[data-js="form"]' }) // Envoie vers le formulaire
        clickToScrollToBlock({ selector: '[data-js="go-to-news"]', scrollTo: '[data-js="news"]' }) // Envoie vers les actualités de l'accueil

        // Calendrier
        calendar() // Calendrier

        // Overlays
        overlayFastlinks() // Overlay des liens rapides
        overlayCalendar() // Overlay du calendrier
        overlaySearch() // Overlay de recherche
        overlayShare() // Overlay de partage
        overlayPopup() // Overlay du popup
        overlayMenu() // Overlay du menu

        // Forms dynamiques
        initFormBuilder()

        // Alerte
        alerts()
      },
      afterLeave () {
        Overlay.destroyAll()
      }
    }]
  })
}
